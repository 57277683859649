import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function GoBackButton() {
  const navigate = useNavigate();
  return (
    <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mb: 3 }}>
      Volver
    </Button>
  );
}
export default GoBackButton;
