import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import CalculateIcon from "@mui/icons-material/Calculate";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import InfoIcon from "@mui/icons-material/Info";
import logo from "./../../assets/images/logo/logo4.png";
import { styled } from "@mui/system";

// Navigation Pages
const pages = [
  { label: "Home", icon: <HomeIcon />, route: "/" },
  { label: "Plan", icon: <MapIcon />, route: "/plan" },
  { label: "Propiedades", icon: <HomeIcon />, route: "/propiedades" },
  { label: "Calculadora", icon: <CalculateIcon />, route: "/calculadora" },
  { label: "Contacto", icon: <ContactMailIcon />, route: "/contacto" },
  { label: "Acerca", icon: <InfoIcon />, route: "/acerca" },
];

const Logo = styled("img")({
  height: 50,
  marginRight: "10px",
});

const ResponsiveAppBar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [visible, setVisible] = useState(true); // Visibility state

  // Check screen size on load and resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for resize

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle visibility of the AppBar on scroll
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setVisible(false); // Hide AppBar on scroll down
      } else {
        setVisible(true); // Show AppBar on scroll up
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Completely hide the AppBar on mobile
  if (isMobile) {
    return null;
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          top: visible ? 0 : "-64px", // Hide AppBar by moving it off-screen
          transition: "top 0.3s ease-in-out", // Smooth transition
          backgroundColor: "#000",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar disableGutters>
          {/* Logo Section */}
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <Logo src={logo} alt="ParcelMint Logo" />
            </Link>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontWeight: 700,
                letterSpacing: ".2rem",
                textTransform: "uppercase",
                color: "white",
              }}
            >
              ParcelMint
            </Typography>
          </Box>

          {/* Desktop Navigation */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.label}
                component={Link}
                to={page.route}
                sx={{
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: "500",
                  textTransform: "none",
                  mx: 2,
                  transition: "color 0.3s",
                  "&:hover": { color: "#0078D4" },
                }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          {/* Account Icon */}
          <Box sx={{ flexGrow: 0 }}>
            <IconButton component={Link} to="/profile" sx={{ color: "white" }}>
              <AccountCircle sx={{ fontSize: 40 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {/* Add padding to avoid overlap */}
      <Box sx={{ height: 64 }} />
    </>
  );
};

export default ResponsiveAppBar;
