import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider,
  Grid,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "./../../firebase/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./components/Admin/admingDashboard";
import Dashboard from "./Dashboard";
import axios from "axios";
import AggregatedTitle from "../Properties/titlePage/getAllUserTitles";
import Loading from "../../components/Loading";

const Profile = () => {
  const { user } = useContext(AuthContext);
  const [favorites, setFavorites] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userProperties, setUserProperties] = useState([]); // New state for user properties
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    // Fetch user data from the API
    const fetchUserData = async () => {
      try {
        const userId = user._id; // Adjust this based on your user object
        const response = await axios.get(`/api/property/user/${userId}`); // Example API endpoint
        setUserData(response.data); // Set the fetched data to state
        console.log("User data fetched:", response.data);

        // Fetch user properties
        const propertiesResponse = await axios.get(
          `/api/property/user/${userId}`
        ); // Example API endpoint
        setUserProperties(propertiesResponse.data); // Set fetched properties
        console.log("User properties fetched:", propertiesResponse.data);
      } catch (error) {
        console.error("Error fetching user data or properties:", error);
      }
    };

    fetchUserData();
  }, [user, navigate]);

  const auth = getAuth();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => console.error("Error signing out:", error));
  };

  return !user ? (
    <Loading />
  ) : (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      {/* Profile Header */}
      {user && (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              textAlign: { xs: "center", sm: "left" },
              mb: 6,
            }}
          >
            <Avatar
              alt={user.firstName + " " + user.lastName}
              src={user.profilePicture}
              sx={{
                width: { xs: 80, sm: 120 },
                height: { xs: 80, sm: 120 },
                mb: { xs: 2, sm: 0 },
                border: "4px solid #1E88E5",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
            <Box
              sx={{ ml: { sm: 4 }, textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                color="primary"
                gutterBottom
                sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
              >
                {user.firstName + " " + user.lastName}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {user.email}
              </Typography>
              <Button
                variant="contained"
                color="error"
                sx={{
                  mt: 2,
                  px: { xs: 2, sm: 4 },
                  py: 1,
                  borderRadius: "20px",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </Box>
          <Divider sx={{ my: 4 }} />
        </Box>
      )}

      {/* Dashboard Section */}
      {user && user.isAdmin ? <Dashboard /> : null}

      {/* User Properties Section */}
      <Box>
        <Button
          component={Link}
          to={`/titles/user/${user ? user._id : null}`}
          variant="contained"
          color="primary"
          sx={{
            alignSelf: "center", // Aligns button horizontally in the card
            borderRadius: "20px",
            fontSize: { xs: "0.8rem", sm: "1rem" },
            px: { xs: 2, sm: 3 },
            py: { xs: 0.5, sm: 1 },
            zIndex: 3, // Ensures the button stays above the overlay
          }}
        >
          Mis Parcelas
        </Button>
        <Typography
          variant="h5"
          fontWeight="bold"
          gutterBottom
          sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}
        >
          Propiedades
        </Typography>

        {userProperties.length === 0 ? (
          <Typography variant="body1" color="text.secondary">
            Aun No Tienes Propiedades.
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {userProperties.map((property) => (
              <Grid item xs={12} sm={6} md={4} key={property._id}>
                <Card
                  elevation={4}
                  sx={{
                    borderRadius: "15px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                    position: "relative",
                    overflow: "hidden", // Ensure content stays within the card
                    cursor: "pointer",
                    backgroundImage: `url(${
                      property.imageUrl || "/default-property.jpg"
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  {/* Overlay for better text visibility */}
                  <Box
                    sx={{
                      position: "absolute",
                      inset: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay for text contrast
                    }}
                  />
                  <CardContent
                    sx={{
                      position: "relative",
                      zIndex: 2,
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      height: "100%", // Ensures content is vertically aligned
                      minHeight: "200px", // Ensures a minimum height for the content area
                      padding: "16px",
                    }}
                  >
                    {/* Property Details */}
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        gutterBottom
                        sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                      >
                        Property ID: {property._id}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="inherit"
                        gutterBottom
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Lotes: {property.totalSquareFootage} | Status:{" "}
                        {property.isSoldOut ? "Vendida" : "En venta"}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="inherit"
                        gutterBottom
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Location: {property.location}
                      </Typography>
                    </Box>

                    {/* Button */}
                    <Button
                      component={Link}
                      to={`/parcels/${property._id}/${property.coordinates.Longitude}/${property.coordinates.Latitude}`}
                      variant="contained"
                      color="primary"
                      sx={{
                        alignSelf: "center", // Aligns button horizontally in the card
                        borderRadius: "20px",
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                        px: { xs: 2, sm: 3 },
                        py: { xs: 0.5, sm: 1 },
                        zIndex: 3, // Ensures the button stays above the overlay
                      }}
                    >
                      Editar
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Favorites Section */}
      <Box sx={{ mt: 6 }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          gutterBottom
          sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}
        >
          Tus Favoritos
        </Typography>
        {favorites.length === 0 ? (
          <Typography variant="body1" color="text.secondary">
            Aun No tienes favoritos.
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {favorites.map((favorite) => (
              <Grid item xs={12} sm={6} md={4} key={favorite.id}>
                <Card
                  elevation={4}
                  sx={{
                    borderRadius: "15px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={favorite.imageUrl}
                    alt={favorite.title}
                    sx={{
                      borderRadius: "15px 15px 0 0",
                      height: { xs: 150, sm: 200 },
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                    >
                      {favorite.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                      sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                    >
                      {favorite.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      sx={{
                        borderRadius: "20px",
                        mt: 2,
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                        px: { xs: 1, sm: 2 },
                        py: { xs: 0.5, sm: 1 },
                      }}
                    >
                      Remove from Favorites
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default Profile;
