import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";

const PitchDeck = () => {
  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      {/* Header Section */}
      <Box
        sx={{
          textAlign: "center",
          py: 6,
          backgroundColor: "#0078D4",
          color: "white",
          borderRadius: "12px",
          mb: 6,
        }}
      >
        <Typography variant="h2" fontWeight="700" gutterBottom>
          🚀 ParcelMint Pitch Deck 🚀
        </Typography>
        <Typography variant="h5">
          <em>"Affordable Property Ownership Made Easy"</em>
        </Typography>
      </Box>

      {/* Problem Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 6, borderRadius: "12px" }}>
        <Typography variant="h4" fontWeight="700" color="primary" gutterBottom>
          The Problem 📉
        </Typography>
        <Typography variant="body1" gutterBottom>
          Real estate remains inaccessible for many people due to high upfront
          costs, complex processes, and lack of affordability. Large properties
          often remain underutilized, leaving huge opportunities untapped.
        </Typography>
      </Paper>

      {/* Solution Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 6, borderRadius: "12px" }}>
        <Typography variant="h4" fontWeight="700" color="primary" gutterBottom>
          The Solution 💡
        </Typography>
        <Typography variant="body1">
          ParcelMint buys large properties at excellent prices, divides them
          into smaller, affordable parcels, and sells them to buyers or
          investors. We handle all legal, administrative, and development
          processes, offering a transparent and hassle-free experience.
        </Typography>
      </Paper>

      {/* Business Model Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 6, borderRadius: "12px" }}>
        <Typography variant="h4" fontWeight="700" color="primary" gutterBottom>
          Business Model 🔑
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          **Example - El Salvador Property**:
        </Typography>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" fontWeight="700">
              📍 Total Land
            </Typography>
            <Typography>280,000 m²</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" fontWeight="700">
              🛣️ Street & Infrastructure
            </Typography>
            <Typography>20,000 m²</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" fontWeight="700">
              🌳 Green Area
            </Typography>
            <Typography>20,000 m²</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" fontWeight="700">
              💰 Purchase Cost
            </Typography>
            <Typography>$550,000</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" fontWeight="700">
              📊 Parcels Sold
            </Typography>
            <Typography>150 parcels (464 m² each)</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" fontWeight="700">
              💵 Sale Price
            </Typography>
            <Typography>$15,000 per parcel</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" fontWeight="700">
          Total Revenue: $2,250,000 | Development & Tax Cost: $800,000
        </Typography>
        <Typography variant="h6" fontWeight="700" color="green">
          Net Profit: $900,000 🚀
        </Typography>
        <Typography sx={{ mt: 2 }}>
          **Remaining Land**: - **Original Remaining Land**: 210,400 m² -
          **Deducted for Street & Green Area**: 40,000 m² - **Final Retained
          Land**: **170,400 m²** for future liquidity or second-round
          development.
        </Typography>
      </Paper>

      {/* Why ParcelMint Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 6, borderRadius: "12px" }}>
        <Typography variant="h4" fontWeight="700" color="primary" gutterBottom>
          Why ParcelMint? 🌟
        </Typography>
        <ul>
          <li>✅ Immediate ROI with first-round sales.</li>
          <li>✅ Significant land retained for future liquidity.</li>
          <li>
            ✅ Streets and green areas enhance property appeal and demand.
          </li>
          <li>✅ Affordable pricing attracts high buyer interest.</li>
          <li>✅ Scalable and replicable across emerging markets.</li>
        </ul>
      </Paper>

      {/* Financial Projections */}
      <Paper elevation={3} sx={{ p: 4, mb: 6, borderRadius: "12px" }}>
        <Typography variant="h4" fontWeight="700" color="primary" gutterBottom>
          Financial Projections 📈
        </Typography>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="700">
              Year 1
            </Typography>
            <Typography>Revenue: $2.25M</Typography>
            <Typography>Profit: $900K</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="700">
              Year 2
            </Typography>
            <Typography>Revenue: $4.5M</Typography>
            <Typography>Profit: $1.8M</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="700">
              Year 3
            </Typography>
            <Typography>Revenue: $6.75M</Typography>
            <Typography>Profit: $2.7M</Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Investment Ask */}
      <Box
        sx={{
          textAlign: "center",
          py: 6,
          backgroundColor: "#0078D4",
          color: "white",
          borderRadius: "12px",
        }}
      >
        <Typography variant="h3" fontWeight="700" gutterBottom>
          Investment Opportunity 💼
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Seeking **$1,000,000** to acquire and develop the first property.
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/contact"
          sx={{
            backgroundColor: "white",
            color: "#0078D4",
            borderRadius: "30px",
            px: 4,
            py: 1.5,
            "&:hover": { backgroundColor: "#f1f1f1" },
          }}
        >
          Get In Touch
        </Button>
      </Box>
    </Container>
  );
};

export default PitchDeck;
