import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    width: "80%",
    maxWidth: "700px",
  },
}));

const TermsDialog = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Ver Términos y Condiciones
      </Button>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>Términos y Condiciones</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Al reservar una parcela, usted acepta los siguientes términos y
            condiciones:
          </Typography>
          <Typography variant="body2" paragraph>
            1. Reservar una parcela no constituye la compra del terreno. La
            reserva asegura un número de parcela para un posible proceso de
            compra.
          </Typography>
          <Typography variant="body2" paragraph>
            2. La compra de una parcela solo podrá realizarse cuando las 100
            parcelas disponibles sean reservadas en su totalidad.
          </Typography>
          <Typography variant="body2" paragraph>
            3. Una vez que las 100 parcelas hayan sido reservadas, el comprador
            deberá proceder con el pago de $2,000.00 por parcela para formalizar
            la compra.
          </Typography>
          <Typography variant="body2" paragraph>
            4. Al completar el pago de $2,000.00, el comprador se compromete a
            seguir todos los pasos y lineamientos establecidos en la página del
            plan.
          </Typography>
          <Button
            fullWidth
            style={{ marginBottom: 5, color: "white" }}
            component={Link}
            to="/plan"
          >
            Ver Plan
          </Button>
          <Typography variant="body2" paragraph>
            5. En caso de que el comprador incumpla con el proceso establecido,
            ParcelMint se reserva el derecho de requerir la devolución de la
            parcela, reembolsando el monto pagado menos los gastos de
            administración y otros cargos aplicables.
          </Typography>
          <Typography variant="body2" paragraph>
            6. El terreno reservado no podrá ser vendido, transferido o
            utilizado como garantía hasta que el comprador reciba las escrituras
            correspondientes en su poder.
          </Typography>
          <Typography variant="body2" paragraph>
            7. Los gastos de administración y otros cargos no serán negociables
            y estarán claramente especificados al momento de la devolución.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default TermsDialog;
