import React, { useContext, useState } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../firebase/AuthContext";
import Loading from "../Loading";

function PurchaseComponent({
  userId,
  userFirstName,
  userLastName,
  propertyId,
  squareFeet,
  price,
  priceUnit,
  description,
  amount,
  option,
  parcelNumber,
  parcelId,
  property,
  termsAccepted,
}) {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const initiatePurchase = async () => {
    // Validate input
    if (!amount || amount <= 0 || amount > 1) {
      alert(
        "Por favor, ingrese una cantidad válida. Solo una parcela por transacción."
      );
      return;
    } else if (!termsAccepted) {
      alert("Por favor aceptart terminos y condiciones");
      return;
    }

    try {
      setLoading(true);
      const apiUrl =
        process.env.NODE_ENV === "production"
          ? "https://www.parcelmint.com/api/property/purchase"
          : "http://localhost:5000/api/property/purchase";

      const response = await axios.post(apiUrl, {
        userId,
        propertyId,
        squareFeet,
        price,
        userFirstName,
        userLastName,
        priceUnit,
        amount,
        option,
        parcelNumber,
        parcelId,
        termsAccepted,
      });

      if (response.data && response.data.approvalUrl) {
        setLoading(false);
        // Redirect to approval URL
        window.location.href = response.data.approvalUrl;
      } else {
        setLoading(false);
        alert(
          "No se pudo obtener la URL de aprobación. Por favor, inténtelo de nuevo."
        );
      }
    } catch (error) {
      handlePurchaseError(error);
    }
  };

  const handlePurchaseError = (error) => {
    if (error.response) {
      console.error("Error del servidor:", error.response.data);
      alert(
        `Error: ${
          error.response.data.message || "Ocurrió un error inesperado."
        }`
      );
    } else {
      console.error("Error de red:", error);
      alert(
        "Error de red. Por favor, revise su conexión e intente nuevamente."
      );
    }
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        width: "100%", // Full width
      }}
    >
      <Loading message={"Loading..."} />
    </Box>
  ) : (
    <div>
      <Typography variant="h5">
        Reserva: {amount} {priceUnit === "squareFoot" ? "Parcela" : ""} por
        transacion
      </Typography>
      <Typography variant="h5">
        Costo de registro: ${new Intl.NumberFormat().format(amount * price)}
      </Typography>

      {user && user._id && property.availableSquareFeet > 0 ? (
        <Button
          sx={{
            width: "100%",
            backgroundColor: "#0078D4",
            color: "white",
            padding: "10px 30px",
            borderRadius: "30px",
            fontSize: "1rem",
            "&:hover": { backgroundColor: "#005FA3" },
          }}
          onClick={initiatePurchase}
        >
          Registrate
        </Button>
      ) : user && user._id ? (
        <Typography
          sx={{
            textAlign: "center",
            color: "red",
            fontWeight: "bold",
            fontSize: "1rem",
            mt: 2,
          }}
        >
          No mas parcelas disponibles por el momento.
        </Typography>
      ) : (
        <Button
          component={Link}
          to="/login"
          sx={{
            width: "100%",
            backgroundColor: "#0078D4",
            color: "white",
            padding: "10px 30px",
            borderRadius: "30px",
            fontSize: "1rem",
            "&:hover": { backgroundColor: "#005FA3" },
          }}
        >
          Registrate o Login
        </Button>
      )}
    </div>
  );
}

export default PurchaseComponent;
