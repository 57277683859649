import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Chip,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

import { AuthContext } from "../firebase/AuthContext";

const ParticipationPlan = () => {
  const [currentStep, setCurrentStep] = useState(0); // Current step index (0-based)
  const { user } = useContext(AuthContext);
  const steps = [
    {
      title: "Paso 1: Registro",
      details:
        "Los participantes deben registrarse oficialmente en el proyecto. El registro tiene un costo de $100, el cual no es reembolsable si cancela su parcela. Este pago garantiza que el proceso sea llevado a cabo de la manera más correcta y seria.",
    },
    {
      title: "Paso 2: Aporte Inicial",
      details: `Realizar un pago inicial de ${
        user ? "$2,000 por lote (464 m²)." : ""
      } Este fondo asegura la participación y se utilizará para la compra del terreno grande. Este paso solo se llevará a cabo una vez que todas las parcelas hayan sido reservadas.`,
    },
    {
      title: "Paso 3: Compra de la Propiedad",
      details:
        "Una vez reunidos los fondos, se procederá a la compra del terreno grande, que será registrado a nombre de Parcelmint hasta la división en lotes.",
    },
    {
      title: "Paso 4: División y Notificación de Lotes",
      details: `El terreno será dividido en lotes de 0xx m² dependiendo del proyecto. Las escrituras individuales serán entregadas tras la notificación legal, ${
        user ? "con un costo estimado entre $300 y $750 por lote." : ""
      }`,
    },
    {
      title: "Paso 5.1: Instalación de Agua Potable",
      details: `Incluye la instalación de tuberías principales y conexiones hacia cada lote. Duración estimada: 2 meses. ${
        user ? "con un costo estimado entre $300 y $750 por lote." : ""
      }`,
    },
    {
      title: "Paso 5.2: Instalación de Electricidad",
      details: `Incluye la instalación de postes, cableado y conexiones eléctricas hacia cada lote. Duración estimada: 3 meses. ${
        user ? "con un costo estimado entre $300 y $750 por lote." : ""
      }`,
    },
    {
      title: "Paso 6: Construcción de Calles Internas",
      details: `Caminos de acceso serán construidos para conectar los lotes, Duración estimada: 3 meses.${
        user ? "con un costo estimado entre $300 y $750 por lote." : ""
      }`,
    },
    {
      title: "Paso 7: Entrega Final",
      details:
        "Los lotes serán entregados con escrituras individuales y servicios básicos completamente instalados. Duración estimada: 1 mes.",
    },
  ];

  // Function to determine the color of a step
  const getStepColor = (index) => {
    if (index < currentStep) return "#FFC107"; // Yellow for completed steps
    if (index === currentStep) return "#4CAF50"; // Green for the current step
    return "#BDBDBD"; // Gray for upcoming steps
  };

  // Function to determine the status message
  const getStepStatus = (index) => {
    if (index < currentStep) return "Hecho"; // Completed
    if (index === currentStep) return "Activo"; // Current
    return "Próximo"; // Upcoming
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      {/* Introduction Section */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom>
          Plan Detallado
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Bienvenidos al proyecto de desarrollo de terrenos PARCELMINT ORIGEN.
          Este documento detalla cómo funcionará el proceso para adquirir,
          desarrollar y disfrutar de su propiedad. A través de un modelo de
          inversión colectiva, haremos posible la adquisición de terrenos a
          precios accesibles y con servicios básicos instalados.
        </Typography>
      </Box>

      {/* Process Steps Section */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" gutterBottom>
          ¿Cómo funciona el proceso?
        </Typography>

        <Grid container spacing={4}>
          {steps.map((step, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                elevation={3}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  border: `2px solid ${getStepColor(index)}`,
                  backgroundColor: `${getStepColor(index)}20`, // Slightly transparent background
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold", color: getStepColor(index) }}
                  >
                    {step.title}
                  </Typography>
                  <Typography variant="body2" color="black">
                    {step.details}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Chip
                      label={getStepStatus(index)}
                      sx={{
                        backgroundColor: getStepColor(index),
                        color: "white",
                        fontWeight: "bold",
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    {index === 0 ? (
                      <Button fullWidth component={Link} to="/propiedades">
                        Gegistrate
                      </Button>
                    ) : null}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Other Sections */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" gutterBottom>
          Cronograma Tentativo
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          La duración estimada para cada etapa del proyecto es la siguiente:
        </Typography>
        <Grid container spacing={4}>
          {[
            { stage: "Registro", duration: "1-2 semanas" },
            { stage: "Aporte Inicial", duration: "1 mes" },
            { stage: "Compra de la Propiedad", duration: "1 mes" },
            { stage: "División y Notificación", duration: "2-5 meses" },
            { stage: "Instalación de Agua", duration: "2 meses" },
            { stage: "Instalación de Electricidad", duration: "3 meses" },
            { stage: "Construcción de Calles Internas", duration: "3 meses" },
            { stage: "Entrega Final", duration: "1 mes" },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                elevation={3}
                sx={{
                  textAlign: "center",
                  border: "1px solid #e0e0e0",
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    {item.stage}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.duration}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Responsibilities Section */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" gutterBottom>
          Responsabilidades de los Participantes
        </Typography>
        <ul>
          <li>
            <Typography color="textSecondary" variant="body1">
              Realizar el registro con un costo de $100.
            </Typography>
          </li>
          <li>
            <Typography color="textSecondary" variant="body1">
              Realizar el pago inicial ${user ? "de $2,000" : ""} por lote para
              asegurar el terreno.
            </Typography>
          </li>
          <li>
            <Typography color="textSecondary" variant="body1">
              Cubrir los costos adicionales de cada etapa (notificación,
              servicios, calles).
            </Typography>
          </li>
          <li>
            <Typography color="textSecondary" variant="body1">
              Participar activamente en las decisiones y mantenerse informado
              sobre el progreso.
            </Typography>
          </li>
        </ul>
      </Box>

      {/* Conclusion Section */}
      <Box>
        <Typography variant="h5" gutterBottom>
          Conclusión
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Este proyecto es una oportunidad única para adquirir terrenos
          accesibles y formar parte de una comunidad organizada con visión a
          futuro.
        </Typography>
      </Box>
    </Container>
  );
};

export default ParticipationPlan;
