import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import {
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import propertyApi from "../../../api/property";
import { AuthContext } from "../../../firebase/AuthContext";
import PurchaseComponent from "../../../components/purchase/PurchaseComponent";
import TermsDialog from "../../../components/purchaseTermsDialog";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Styled components for better design
const StyledMapContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(3),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    width: "80%", // Enlarged dialog width
    maxWidth: "1000px", // Limit max width
    height: "auto", // Dynamic height
    maxHeight: "80vh", // Prevent overflow
  },
}));

const ParcelViewer = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams(); // Get propertyId from URL params
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedParcel, setSelectedParcel] = useState(null); // Track selected parcel
  const mapContainer = useRef(null); // Reference to the map container
  const map = useRef(null); // Reference to the map instance
  const [termsAccepted, setTermsAccepted] = useState(false); // State management for toggle
  console.log("terms ", termsAccepted);
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await propertyApi.getParcelById(id);
        console.log("parcel response ", response.data);
        setProperty(response.data);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch property.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  useEffect(() => {
    if (loading || !property.parcels || property.parcels.length === 0) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [property.coordinates.Longitude, property.coordinates.Latitude],
      zoom: 18,
    });

    map.current.on("style.load", () => {
      property.parcels.forEach((parcel) => {
        if (parcel.coordinates?.length > 0) {
          map.current.addSource(`parcel-${parcel._id}`, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [parcel.coordinates],
              },
              properties: {
                id: parcel._id,
                estado: parcel.estado,
                parcelNumber: parcel.parcelNumber,
                owner: parcel.owner,
              },
            },
          });

          // Fill layer
          map.current.addLayer({
            id: `parcel-layer-${parcel._id}`,
            type: "fill",
            source: `parcel-${parcel._id}`,
            paint: {
              "fill-color": getParcelColor(parcel.estado, parcel.owner),
              "fill-opacity": 0.5,
            },
          });

          // Parcel number overlay
          map.current.addLayer({
            id: `parcel-number-${parcel._id}`,
            type: "symbol",
            source: `parcel-${parcel._id}`,
            layout: {
              // Combine parcel number and owner's first name into one text-field
              "text-field": `#${parcel.parcelNumber}. - ${
                parcel && parcel.owner
                  ? parcel.owner.firstName
                  : parcel.estado === "reserved"
                  ? "Propiedad de Parcelmint"
                  : "Disponible"
              }`,
              "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
              "text-size": 12,
              "text-offset": [0, 0.5],
              "text-anchor": "top",
            },
            paint: {
              "text-color": "black",
            },
          });
        }
      });

      // Parcel click handler
      map.current.on("click", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: property.parcels.map(
            (parcel) => `parcel-layer-${parcel._id}`
          ),
        });

        if (features.length > 0) {
          const clickedParcel = features[0].properties;
          const parcelDetails = property.parcels.find(
            (p) => p._id === clickedParcel.id
          );
          setSelectedParcel(parcelDetails);
        }
      });
    });

    return () => {
      if (map.current) map.current.remove();
    };
  }, [property, loading]);

  const getParcelColor = (estado, ownerId) => {
    switch (estado) {
      case "vendida":
        return ownerId._id === user._id ? "#0a74da" : "#ff4d4d";
      case "en venta":
        return ownerId === user._id ? "#00a550" : "#39d177";
      case "reserved":
        return "#ffd700";
      default:
        return "#cccccc";
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
        <Typography mt={2}>Loading property...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
  const handleToggleChange = () => {
    setTermsAccepted((prev) => !prev);
  };
  return (
    <Box mt={4}>
      <StyledMapContainer ref={mapContainer} />

      {selectedParcel && (
        <StyledDialog
          open={Boolean(selectedParcel)}
          onClose={() => setSelectedParcel(null)}
        >
          <DialogTitle>Detalles</DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Parcela #{selectedParcel.parcelNumber}
            </Typography>
            <Chip
              label={
                selectedParcel.estado === "vendida"
                  ? "Reservada"
                  : selectedParcel.estado === "reserved"
                  ? "parcelmint"
                  : "disponible"
              }
              color={
                selectedParcel.estado === "vendida"
                  ? "error"
                  : selectedParcel.estado === "en venta"
                  ? "success"
                  : "warning"
              }
            />
            <Typography>
              Area: {property.priceUnit === "acre" ? 1 : selectedParcel.area}{" "}
              {property.priceUnit === "acre" ? "acre" : "parcela"}
            </Typography>

            {selectedParcel.owner && (
              <Typography>
                Reservada por: {selectedParcel.owner.firstName}{" "}
                {selectedParcel.owner.lastName}
              </Typography>
            )}
          </DialogContent>
          {selectedParcel.estado === "vendida" ||
          selectedParcel.estado === "reserved" ? null : (
            <DialogActions>
              <PurchaseComponent
                userFirstName={user?.firstName || ""}
                userLastName={user?.lastName || ""}
                userId={user?._id || ""}
                description={selectedParcel.description}
                property={property}
                propertyId={property._id}
                amount={1}
                termsAccepted={termsAccepted}
                price={property.reservePrice}
                parcelId={selectedParcel._id}
                priceUnit={"squareFoot"}
                option={"presale"}
              />
            </DialogActions>
          )}
          {/* Toggle Button */}
          {selectedParcel.owner ||
          selectedParcel.estado === "reserved" ? null : (
            <DialogActions>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      required
                      checked={termsAccepted}
                      onChange={handleToggleChange}
                      color="success"
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: termsAccepted ? "green" : "black",
                        fontWeight: termsAccepted ? "bold" : "normal",
                      }}
                    >
                      {termsAccepted
                        ? "Términos y condiciones aceptados"
                        : "Aceptar términos y condiciones"}
                    </Typography>
                  }
                />
              </FormGroup>
            </DialogActions>
          )}
          {selectedParcel.owner ||
          selectedParcel.estado === "reserved" ? null : (
            <DialogActions>
              <TermsDialog />
            </DialogActions>
          )}
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setSelectedParcel(null)}
            >
              Cerrar
            </Button>
          </DialogActions>
        </StyledDialog>
      )}
    </Box>
  );
};

export default ParcelViewer;
