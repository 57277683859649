import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import pmth4 from "./../assets/images/pmt4.PNG";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <Container maxWidth={false} disableGutters>
      {/* Hero Section */}
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${pmth4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          color: "white",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            paddingX: { xs: 2, sm: 4 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2.5rem", sm: "4rem" },
              marginBottom: 2,
              color: "white",
            }}
          >
            Construye tu Futuro con ParcelMint Hoy!
          </Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: "600px",
              margin: "0 auto",
              fontSize: { xs: "1rem", sm: "1.5rem" },
              marginBottom: 4,
            }}
          >
            Innovamos la forma de ser propietario de terrenos. Accede a parcelas
            accesibles y forma parte de una comunidad revolucionaria.
          </Typography>
          <Button
            component={Link}
            to={"/propiedades"}
            variant="contained"
            sx={{
              backgroundColor: "#0078D4",
              color: "white",
              padding: "10px 30px",
              borderRadius: "30px",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#005FA3" },
            }}
          >
            Ver Parcelas Disponibles
          </Button>
        </Box>
      </Box>

      {/* Added Section */}
      <Box
        sx={{
          paddingY: 6,
          backgroundColor: "#F4F4F4",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontWeight: 700, marginBottom: 4 }}
          color="primary"
        >
          Somos ParcelMint
        </Typography>
        <Container maxWidth="md">
          <Typography
            variant="body1"
            color="black"
            sx={{ fontSize: "1.25rem", marginBottom: 3 }}
          >
            Somos ParcelMint, una iniciativa revolucionaria que busca hacer
            realidad el sueño de adquirir tierra a precios accesibles. Imagina
            ser parte de una comunidad exclusiva donde cada paso del proceso es
            transparente, asequible y enfocado en el beneficio de todos los
            involucrados.
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: 3 }}
            color="text.primary"
          >
            ¿Por qué nos conviene unirnos?
          </Typography>
          <Typography
            variant="body1"
            color="black"
            sx={{ fontSize: "1rem", marginBottom: 3 }}
          >
            Porque juntos podemos negociar precios más bajos y repartir los
            gastos de topografía, permisos, y servicios como agua, luz y calles.
            Porque comprar en conjunto nos permite tener escrituras en mano por
            mucho menos de lo que pagarías individualmente. Porque esta
            comunidad no solo es una inversión económica, sino una oportunidad
            para construir un futuro mejor.
          </Typography>
          <Typography
            variant="body1"
            color="black"
            sx={{ fontSize: "1rem", marginBottom: 3 }}
          >
            Soy Bladimir, un joven salvadoreño que está cansado de los precios
            inalcanzables de los terrenos. Fundé ParcelMint con el propósito de
            cambiar las reglas del juego y hacer posible lo que parece
            inalcanzable para una sola persona. Juntos, podemos crear una
            comunidad exclusiva y asequible, donde cada miembro sea dueño de su
            propio terreno con un proceso seguro y transparente.
          </Typography>
          <Button
            component={Link}
            to={"/propiedades"}
            variant="contained"
            sx={{
              backgroundColor: "#0078D4",
              color: "white",
              padding: "10px 30px",
              borderRadius: "30px",
              marginTop: 4,
              "&:hover": { backgroundColor: "#005FA3" },
            }}
          >
            ¡Hagámoslo realidad!
          </Button>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ paddingY: 6, minHeight: "100vh" }}>
        {/* Rest of the content */}
      </Box>
    </Container>
  );
};

export default HomePage;
