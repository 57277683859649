import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: "2rem",
        fontFamily:
          "'San Francisco', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
        lineHeight: 1.8,
        color: "black", // Set all content color to black
      }}
    >
      {/* Sección de Título */}
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Typography
          variant="h3"
          component="h1"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          Términos y Condiciones
        </Typography>
        <Typography variant="body2" sx={{ color: "black" }}>
          Última Actualización: [Insertar Fecha]
        </Typography>
      </Box>

      {/* Sección de Contenido */}
      <Box>
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          1. Introducción
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Bienvenido a ParcelMint. Estos Términos y Condiciones describen las
          reglas y regulaciones para usar nuestra plataforma y servicios. Al
          acceder o usar ParcelMint, usted acepta cumplir con estos términos. Si
          no está de acuerdo, por favor absténgase de usar nuestra plataforma.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          2. Uso de los Servicios
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Se le concede un derecho limitado, no exclusivo e intransferible para
          usar nuestra plataforma exclusivamente para fines personales o
          comerciales internos. Cualquier uso no autorizado de la plataforma
          está estrictamente prohibido y puede resultar en la terminación del
          acceso.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          3. Responsabilidades del Usuario
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Al usar ParcelMint, usted acepta:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          sx={{ paddingLeft: "2rem", color: "black" }}
        >
          <li>
            Proporcionar información precisa, actualizada y completa al crear
            una cuenta.
          </li>
          <li>
            Mantener la seguridad y confidencialidad de sus credenciales de
            cuenta.
          </li>
          <li>
            Abstenerse de participar en actividades ilegales o actividades que
            interrumpan nuestros servicios.
          </li>
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          4. Propiedad Intelectual
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Todo el contenido, incluidos, entre otros, textos, gráficos, logotipos
          y software, es propiedad de ParcelMint o sus licenciantes y está
          protegido por las leyes de propiedad intelectual aplicables. No puede
          copiar, reproducir, distribuir o crear obras derivadas sin nuestro
          consentimiento explícito.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          5. Pagos y Transacciones
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Los pagos realizados a través de ParcelMint se procesan de manera
          segura. Usted es responsable de asegurarse de que toda la información
          de pago proporcionada sea precisa. Los reembolsos o cancelaciones, si
          corresponde, se manejarán de acuerdo con nuestra política de
          reembolsos.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          6. Limitación de Responsabilidad
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          ParcelMint no será responsable de ningún daño directo, indirecto,
          incidental o consecuente que surja de su uso de la plataforma o la
          imposibilidad de usarla, incluidos, entre otros, la pérdida de datos,
          ingresos o ganancias.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          7. Modificaciones
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          ParcelMint se reserva el derecho de actualizar o modificar estos
          Términos y Condiciones en cualquier momento. Los cambios se publicarán
          en esta página, y el uso continuo de la plataforma constituye la
          aceptación de los términos revisados.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          8. Ley Aplicable
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Estos Términos y Condiciones se regirán e interpretarán de acuerdo con
          las leyes de [Insertar Jurisdicción].
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem", color: "black" }}
        >
          9. Información de Contacto
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "black" }}>
          Si tiene alguna pregunta o inquietud sobre estos Términos, contáctenos
          en{" "}
          <Link
            href="mailto:support@parcelmint.com"
            sx={{ textDecoration: "none", color: "black" }}
          >
            support@parcelmint.com
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
