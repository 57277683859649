import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  Container,
} from "@mui/material";

const AreaCalculator = () => {
  const SQUARE_FEET_PER_SQUARE_VARA = 9; // 1 vara cuadrada = 9 pies cuadrados
  const SQUARE_METERS_PER_SQUARE_VARA = 0.6987; // 1 vara cuadrada = 0.6987 metros cuadrados
  const SQUARE_FEET_PER_SQUARE_METER = 10.7639; // 1 metro cuadrado = 10.7639 pies cuadrados

  const [squareFeet, setSquareFeet] = useState("");
  const [squareVaras, setSquareVaras] = useState("");
  const [squareMeters, setSquareMeters] = useState("");

  const handleSquareFeetChange = (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      clearFields();
      return;
    }
    setSquareFeet(value);
    setSquareVaras((value / SQUARE_FEET_PER_SQUARE_VARA).toFixed(2));
    setSquareMeters((value / SQUARE_FEET_PER_SQUARE_METER).toFixed(2));
  };

  const handleSquareVarasChange = (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      clearFields();
      return;
    }
    setSquareVaras(value);
    setSquareFeet((value * SQUARE_FEET_PER_SQUARE_VARA).toFixed(2));
    setSquareMeters((value * SQUARE_METERS_PER_SQUARE_VARA).toFixed(2));
  };

  const handleSquareMetersChange = (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      clearFields();
      return;
    }
    setSquareMeters(value);
    setSquareFeet((value * SQUARE_FEET_PER_SQUARE_METER).toFixed(2));
    setSquareVaras((value / SQUARE_METERS_PER_SQUARE_VARA).toFixed(2));
  };

  const clearFields = () => {
    setSquareFeet("");
    setSquareVaras("");
    setSquareMeters("");
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Calculadora de Unidades de Área
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color="text.secondary"
        gutterBottom
      >
        Convierte entre pies cuadrados, varas cuadradas y metros cuadrados con
        precisión.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Pies Cuadrados"
              type="number"
              value={squareFeet}
              onChange={handleSquareFeetChange}
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": { color: "#444" },
                "& .MuiOutlinedInput-root": {
                  "& input": { color: "#444" },
                  "& fieldset": { borderColor: "#999" },
                  "&:hover fieldset": { borderColor: "#444" },
                  "&.Mui-focused fieldset": { borderColor: "#1976d2" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Varas Cuadradas"
              type="number"
              value={squareVaras}
              onChange={handleSquareVarasChange}
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": { color: "#444" },
                "& .MuiOutlinedInput-root": {
                  "& input": { color: "#444" },
                  "& fieldset": { borderColor: "#999" },
                  "&:hover fieldset": { borderColor: "#444" },
                  "&.Mui-focused fieldset": { borderColor: "#1976d2" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Metros Cuadrados"
              type="number"
              value={squareMeters}
              onChange={handleSquareMetersChange}
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": { color: "#444" },
                "& .MuiOutlinedInput-root": {
                  "& input": { color: "#444" },
                  "& fieldset": { borderColor: "#999" },
                  "&:hover fieldset": { borderColor: "#444" },
                  "&.Mui-focused fieldset": { borderColor: "#1976d2" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              onClick={clearFields}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                mt: 2,
                px: 5,
                borderRadius: "25px",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Limpiar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AreaCalculator;
