import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";

const TerminosDelSorteo = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          Términos y Condiciones del Sorteo
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ padding: 2 }}>
          <Typography variant="body1" paragraph>
            El ganador del sorteo obtendrá 1 acre adicional de terreno. Sin
            embargo, para mantener esta propiedad, el ganador debe cumplir con
            los siguientes requisitos:
          </Typography>
          <Typography variant="body1" paragraph>
            1. Debe continuar pagando el acre que compró originalmente para
            participar en el sorteo.
          </Typography>
          <Typography variant="body1" paragraph>
            2. Si el ganador decide dejar de pagar el acre que compró,
            automáticamente perderá tanto la propiedad comprada como la
            propiedad ganada en el sorteo.
          </Typography>
          <Typography variant="body1" paragraph>
            3. Es obligatorio comprometerse a realizar los pagos mensuales del
            acre comprado, ya que es un requisito indispensable para participar
            y mantener la propiedad ganada.
          </Typography>
          <Typography variant="body1">
            Al participar en este sorteo, usted acepta y comprende estos
            términos y condiciones.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TerminosDelSorteo;
