import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import {
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
  Grid,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const InteractiveParcelEditor = () => {
  const { propertyId, Latitude, Longitude } = useParams(); // Get propertyId from route params
  const mapContainer = useRef(null);
  const map = useRef(null);
  const draw = useRef(null); // Mapbox Draw instance
  const [propertyBoundary, setPropertyBoundary] = useState([]);
  const [isDrawingBoundary, setIsDrawingBoundary] = useState(false);
  const [parcelsX, setParcelsX] = useState(1);
  const [parcelsY, setParcelsY] = useState(5);
  const [generatedParcels, setGeneratedParcels] = useState([]);
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (map.current) return;

    // Initialize Mapbox map
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [Latitude, Longitude],
      zoom: 18,
    });

    // Initialize Mapbox Draw
    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: true,
      },
    });

    map.current.addControl(draw.current);

    map.current.on("click", (e) => {
      if (!isDrawingBoundary) return;
      const lngLat = [e.lngLat.lng, e.lngLat.lat];
      setPropertyBoundary((prev) => [...prev, lngLat]);
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [isDrawingBoundary]);

  useEffect(() => {
    if (!map.current || propertyBoundary.length === 0) return;

    const boundaryFeature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [propertyBoundary.concat([propertyBoundary[0]])],
      },
    };

    if (map.current.getSource("boundary")) {
      map.current.getSource("boundary").setData(boundaryFeature);
    } else {
      map.current.addSource("boundary", {
        type: "geojson",
        data: boundaryFeature,
      });

      map.current.addLayer({
        id: "boundary",
        type: "line",
        source: "boundary",
        paint: {
          "line-color": "#ff0000",
          "line-width": 2,
        },
      });
    }
  }, [propertyBoundary]);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const showMessage = (msg) => {
    setMessage(msg);
    setSnackbarOpen(true);
  };

  const startDrawingBoundary = () => {
    setIsDrawingBoundary(true);
    showMessage(
      "Haz clic en el mapa para definir los puntos del límite. Haz clic en 'Detener Dibujo' cuando termines."
    );
  };

  const stopDrawingBoundary = () => {
    setIsDrawingBoundary(false);
    if (propertyBoundary.length < 3) {
      showMessage(
        "El límite es incompleto. Por favor, define al menos tres puntos."
      );
      setPropertyBoundary([]);
    } else {
      showMessage("Dibujo del límite detenido.");
    }
  };

  const generateParcels = () => {
    if (propertyBoundary.length < 3) {
      showMessage("Por favor completa primero el límite de la propiedad.");
      return;
    }

    const propertyPolygon = turf.polygon([
      propertyBoundary.concat([propertyBoundary[0]]),
    ]);

    const bbox = turf.bbox(propertyPolygon);
    const cellWidth = (bbox[2] - bbox[0]) / parcelsX;
    const cellHeight = (bbox[3] - bbox[1]) / parcelsY;

    const grid = turf.rectangleGrid(bbox, cellWidth, cellHeight, {
      units: "degrees",
    });

    const parcels = [];
    turf.featureEach(grid, (cell) => {
      if (turf.booleanIntersects(cell, propertyPolygon)) {
        parcels.push(cell);
      }
    });

    parcels.forEach((parcel, index) => {
      const id = `parcel #${index}`;
      draw.current.add({
        id,
        type: "Feature",
        geometry: parcel.geometry,
        properties: { id },
      });
    });

    const parcelData = parcels.map((parcel, index) => ({
      id: `parcel-${index}`,
      coordinates: parcel.geometry.coordinates[0],
    }));

    setGeneratedParcels(parcelData);
    showMessage(
      "¡Parcelas generadas! Ahora puedes moverlas o redimensionarlas."
    );
  };

  const enableEditing = () => {
    draw.current.changeMode("simple_select");
    showMessage(
      "Modo de edición activado. Puedes mover o redimensionar parcelas."
    );
  };

  const resetMap = () => {
    setPropertyBoundary([]);
    setGeneratedParcels([]);
    setIsDrawingBoundary(false);

    if (map.current.getSource("boundary")) {
      map.current.removeLayer("boundary");
      map.current.removeSource("boundary");
    }

    draw.current.deleteAll();
    showMessage("Mapa reiniciado.");
  };

  const saveParcels = async () => {
    const parcels = draw.current.getAll().features.map((feature) => ({
      id: feature.properties.id,
      coordinates: feature.geometry.coordinates[0],
    }));

    if (parcels.length === 0) {
      showMessage("No hay parcelas para guardar. Genera parcelas primero.");
      return;
    }

    try {
      const response = await fetch("/api/parcels", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ parcels, propertyId }),
      });

      if (response.ok) {
        showMessage("¡Parcelas guardadas con éxito!");
      } else {
        showMessage("Error al guardar las parcelas en la base de datos.");
      }
    } catch (error) {
      console.error("Error saving parcels:", error);
      showMessage("Error al guardar las parcelas en la base de datos.");
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Editor de Parcelas
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6} sm={3}>
          <TextField
            fullWidth
            label="Parcelas en X"
            type="number"
            value={parcelsX}
            onChange={(e) => setParcelsX(Number(e.target.value))}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            fullWidth
            label="Parcelas en Y"
            type="number"
            value={parcelsY}
            onChange={(e) => setParcelsY(Number(e.target.value))}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={3}>
        <Grid item>
          <Button variant="contained" onClick={startDrawingBoundary}>
            Iniciar Límite
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={stopDrawingBoundary}>
            Detener Dibujo
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={generateParcels}>
            Generar Parcelas
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={enableEditing}>
            Activar Edición
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={saveParcels}>
            Guardar Parcelas
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="error" onClick={resetMap}>
            Reiniciar
          </Button>
        </Grid>
      </Grid>
      <Box ref={mapContainer} sx={{ width: "100%", height: "70vh" }} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="info" onClose={handleSnackbarClose}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InteractiveParcelEditor;
