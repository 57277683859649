// App.js
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { auth } from "./firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Profile from "./views/private/profile";
import Account from "./views/private/Account";
import Home from "./views/Home";
import PrivateRoute from "./private/index"; // Import the PrivateRoute
import Login from "./views/login";
import Signup from "./views/signup";
import NoFound from "./views/NoFound";
import RedirectIfLoggedIn from "./private/RedirectIfLoggedIn";
import Navbar from "./components/Navbar.module.css/Navbar";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/Theme/theme";
import About from "./views/About";
import ResponsiveAppBar from "./components/NavBar";
import Roadmap from "./views/RoadMap";

import Contact from "./views/Contact";
import Properties from "./views/Properties/Properties";
import PropertyDetail from "./views/Properties/PropertyDetailPage";
import ExampleComponent from "./views/translation";
import AddProperty from "./views/private/components/Admin/views/addProperty";
import PropertyListPage from "./views/private/components/Admin/views/PropertyListPage";
import PurchaseComponent from "./components/purchase/PurchaseComponent";
import PaymentSuccess from "./components/purchase/PaymentSuccess";

import InvestmentTiers from "./views/investorDasboard/InvestmentTiersPage";
import InvestorDashboard from "./views/investorDasboard/investorDashboard";
import { AuthContext } from "./firebase/AuthContext";
import NoInvestorNavbar from "./components/NavBar/noinvestorsNavbar";
import TitlePage from "./views/Properties/titlePage/TitlePage";
import UserTitles from "./views/Properties/titlePage/getAllUserTitles";
import BusinessPlan from "./views/businessPlan/businessPlan";
import StickyFooter from "./components/footer";
import PresalePage from "./views/pmt-token/PresalePage";
import AreaCalculator from "./components/calculator/calculator";
import TransactionHistory from "./views/Properties/TransactionHistory/TransactionHistory";
import ParcelManager from "./components/map/ParcelManager";
import FetchProperties from "./views/Properties/adminProperties/getAllProperties";
import ParcelViewer from "./views/Properties/parcelviewer";
import TermsAndConditions from "./components/TermsAndConditions";
import PropertyChatPage from "./components/PropertyMessaging/propertyChatPage";
import OwnerMessaging from "./components/messaging/ownerMessages/OwnerMessaging";
import BusinessProfilePage from "./views/Business/BusinessProfile";
import PublicBusinessPage from "./views/PublicBusinessPage";
import ParticipationPlan from "./views/planExplicativo";
import BottomNav from "./components/bottomNav";
import MenuPage from "./components/menu";
import PitchDeck from "./components/investorPitch/pitch-deck";
import UsersList from "./views/private/components/Admin/viewAllUsers";
function App() {
  const { user } = useContext(AuthContext);
  console.log("user app.js", user);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <ResponsiveAppBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />

            <Route path="/contacto" element={<Contact />} />
            <Route path="/propiedades" element={<Properties />} />
            <Route path="/add-property" element={<AddProperty />} />
            <Route path="/calculadora" element={<AreaCalculator />} />
            <Route path="/property/:id" element={<PropertyDetail />} />
            <Route path="/property/parcel/:id" element={<ParcelViewer />} />
            <Route path="/property-list" element={<PropertyListPage />} />
            <Route path="/property-details/:id" element={<PropertyDetail />} />
            <Route path="/titles/title/:titleID" element={<TitlePage />} />
            <Route path="/plan" element={<ParticipationPlan />} />

            <Route
              path="/business-name/:businessId"
              element={<BusinessProfilePage />}
            />
            <Route
              path="/business-name/id/:businessId"
              element={<PublicBusinessPage />}
            />
            <Route path="/titles/user/:userId" element={<UserTitles />} />
            <Route
              path="/properties/admin/view"
              element={<FetchProperties />}
            />
            <Route path="/success" element={<PaymentSuccess />} />
            <Route
              path="/transactions/user/:userId"
              element={<TransactionHistory />}
            />
            <Route path="/users" element={<UsersList />} />
            <Route
              path="/messages/:parcelId/:userId/:ownerId/:propertyId"
              element={<PropertyChatPage />}
            />
            <Route path="/messages/owner" element={<OwnerMessaging />} />

            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/pitch-deck" element={<PitchDeck />} />
            <Route
              path="/parcels/:propertyId/:Latitude/:Longitude"
              element={<ParcelManager />}
            />

            <Route path="/translation" element={<ExampleComponent />} />

            <Route path="/signup" element={<Signup />} />
            <Route path="/acerca" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/investor-dashboard" element={<InvestorDashboard />} />
            <Route path="*" element={<NoFound />} />
          </Routes>
          <StickyFooter />
          <BottomNav />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
