import { createTheme } from "@mui/material/styles";

// Improved Sovryn-inspired theme with a refined color palette
const sovrynTheme = createTheme({
  palette: {
    mode: "dark", // Dark mode enabled
    primary: {
      main: "#2563EB", // Rich bright blue for primary actions
      contrastText: "#FFFFFF", // White text on buttons
    },
    secondary: {
      main: "#14B8A6", // Soft teal for secondary actions
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#0A0F1D", // Deep navy blue for a modern look
      paper: "#111827", // Slightly lighter shade for cards and surfaces
    },
    text: {
      primary: "#F3F4F6", // Near-white for primary text
      secondary: "#9CA3AF", // Light gray for secondary text
      disabled: "#6B7280", // Muted gray for disabled elements
    },
    divider: "#1F2937", // Subtle divider color
    success: {
      main: "#22C55E", // Soft green for success messages
    },
    error: {
      main: "#EF4444", // Vibrant red for error states
    },
    warning: {
      main: "#F59E0B", // Warm yellow for warnings
    },
    info: {
      main: "#3B82F6", // Bright blue for information messages
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', sans-serif", // Clean, modern font
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
      letterSpacing: "-0.015em",
      color: "#F3F4F6",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
      color: "#E5E7EB",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.75rem",
      color: "#E5E7EB",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      color: "#D1D5DB",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      color: "#9CA3AF",
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      letterSpacing: "0.05em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontWeight: "bold",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: "#1D4ED8", // Darker blue on hover
            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.4)",
          },
        },
        containedPrimary: {
          backgroundColor: "#2563EB",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#1D4ED8",
          },
        },
        containedSecondary: {
          backgroundColor: "#14B8A6",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#0F766E",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#111827", // Card background
          borderRadius: "12px",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.5)",
          color: "#F3F4F6",
          padding: "24px",
          "&:hover": {
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.6)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "#374151" },
            "&:hover fieldset": { borderColor: "#2563EB" },
            "&.Mui-focused fieldset": { borderColor: "#2563EB" },
          },
          "& input": {
            color: "#F3F4F6",
          },
          "& label": {
            color: "#9CA3AF",
          },
          "& label.Mui-focused": {
            color: "#2563EB",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#1F2937",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "12px 16px",
          "& .MuiAlert-icon": {
            color: "#FFFFFF",
          },
        },
      },
    },
  },
});

export default sovrynTheme;
