import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  Alert,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import propertyApi from "../../api/property";
import { AuthContext } from "../../firebase/AuthContext";
import ShareButtons from "../../components/shareButtons";
import GoBackButton from "../../components/goBackButton";
import ParcelViewer from "./parcelviewer";
import Loading from "../../components/Loading";
import SorteoPropiedad from "../../components/SorteoPropiedad/SorteoPropiedad";
import property from "../../api/property";
import TerminosDelSorteo from "../../components/SorteoPropiedad/TerminosDelSorteo";

const DetallePropiedad = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [propiedad, setPropiedad] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await propertyApi.getById(id);
        setPropiedad(response.data);
      } catch (err) {
        setError("No se pudo cargar la propiedad.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProperty();
  }, [id]);

  if (loading) {
    return <Loading message={"Cargando Parcelas"} />;
  }

  if (error || !propiedad) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: "center" }}>
        <Typography variant="h4">
          {error || "Propiedad no encontrada"}
        </Typography>
        <Button
          component={Link}
          to="/properties"
          variant="contained"
          sx={{ mt: 2 }}
        >
          Volver a las Propiedades
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>{propiedad.title}</title>
        <meta property="og:title" content={propiedad.title} />
        <meta property="og:description" content={propiedad.description} />
        <meta property="og:image" content={propiedad.imageUrl} />
      </Helmet>

      {/* Go Back Button */}
      <GoBackButton />
      {/* Alert for Non-Logged-in Users */}
      <Grid item xs={12} md={6}>
        {!user ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Inicia sesión para ver las parcelas disponibles. {"  "}
            <Button component={Link} to="/login" variant="contained">
              Inicia sesión
            </Button>
          </Alert>
        ) : null}
        {/* Parcel Viewer as Header */}
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            borderRadius: 2,
            overflow: "hidden",
            mb: 4,
          }}
        >
          <ParcelViewer />
        </Box>
      </Grid>

      {/* Content */}
      <Grid container spacing={4}>
        {/* Property Details */}
        <Grid item xs={12} md={6}>
          <Typography color="black" variant="h3" fontWeight="bold" gutterBottom>
            {propiedad.title}
          </Typography>
          <Typography variant="body1" color="black" paragraph>
            {propiedad.description}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h5" sx={{ mb: 2 }}>
            Aporte: ${propiedad.financingOptions.downPayment} por parcela
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Ubicación: {propiedad.location}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Parcelas Disponibles: {propiedad.availableSquareFeet}
          </Typography>

          {/* Share Buttons */}
          <ShareButtons />
          <TerminosDelSorteo />
          <SorteoPropiedad parcels={propiedad.parcels} />
          {/* CTA Button */}
          {propiedad.availableSquareFeet > 0 ? (
            ""
          ) : (
            <Typography
              color="error"
              variant="h6"
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              Sold Out
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Features Section */}
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>
          Características del Proyecto
        </Typography>
        <Grid container spacing={3}>
          {propiedad.sections.map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: "100%", boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h5" color="primary" gutterBottom>
                    {section.icon} {section.heading}
                  </Typography>
                  <ul>
                    {section.content.map((item, idx) => (
                      <li key={idx}>
                        <Typography variant="body2" color="text.secondary">
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default DetallePropiedad;
