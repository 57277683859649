import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import CalculateIcon from "@mui/icons-material/Calculate";
import MenuIcon from "@mui/icons-material/Menu";
import LandscapeIcon from "@mui/icons-material/Landscape";

const Navigation = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [navValue, setNavValue] = useState(0);
  const [visible, setVisible] = useState(true); // State to toggle visibility
  const navigate = useNavigate();

  // Navigation links
  const navLinks = [
    { label: "Home", icon: <HomeIcon />, route: "/" },
    { label: "Propiedades", icon: <LandscapeIcon />, route: "/propiedades" },
    { label: "Plan", icon: <MapIcon />, route: "/plan" },
    { label: "Calculadora", icon: <CalculateIcon />, route: "/calculadora" },
    { label: "Menu", icon: <MenuIcon />, route: "/menu" },
  ];

  // Check screen size on load and resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for resize

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle visibility of the navigation on scroll
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setVisible(false); // Hide navigation on scroll down
      } else {
        setVisible(true); // Show navigation on scroll up
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {isMobile ? (
        // Mobile Bottom Navigation
        <Box
          sx={{
            width: "100%",
            position: "fixed",
            bottom: visible ? 0 : "-100px", // Hide when not visible
            backgroundColor: "#0F172A",
            zIndex: 1000,
            transition: "bottom 0.3s ease-in-out", // Smooth transition
          }}
        >
          <BottomNavigation
            showLabels
            value={navValue}
            onChange={(event, newValue) => {
              setNavValue(newValue);
              navigate(navLinks[newValue].route);
            }}
            sx={{
              backgroundColor: "#1E293B",
              "& .Mui-selected": {
                color: "#38BDF8",
              },
              height: 70, // Adjusted height
            }}
          >
            {navLinks.map((link, index) => (
              <BottomNavigationAction
                key={index}
                label={link.label}
                icon={link.icon}
                sx={{
                  color: "white",
                  padding: "10px", // Add padding for better spacing
                  "& .MuiSvgIcon-root": { fontSize: "2rem" }, // Increase icon size
                  "& .MuiBottomNavigationAction-label": {
                    fontSize: "0.875rem", // Increase label size
                  },
                }}
              />
            ))}
          </BottomNavigation>
        </Box>
      ) : null}
    </>
  );
};

export default Navigation;
