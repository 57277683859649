import React, { useState, useContext, useEffect } from "react";
import { auth } from "./../firebase/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "./../firebase/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  Alert,
  CircularProgress,
  Container,
} from "@mui/material";
import userApi from "./../api/user";

function Signup() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
  }, [user, navigate]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const createdAt = Date.now();
    try {
      const userData = {
        firstName,
        lastName,
        email,
        password,
        createdAt,
      };

      await createUserWithEmailAndPassword(auth, email, password);
      await userApi.create(userData);
      navigate("/profile");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #0F172A, #1E293B)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            backgroundColor: "#1E293B",
            borderRadius: "12px",
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.5)",
            color: "#E2E8F0",
            padding: 4,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="#F8FAFC"
            gutterBottom
          >
            Crea una Cuenta
          </Typography>
          <Typography variant="body2" color="#CBD5E1" sx={{ marginBottom: 2 }}>
            Únete a ParcelMint y explora oportunidades exclusivas.
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2, color: "#F87171" }}>
              {error}
            </Alert>
          )}

          <Box
            component="form"
            noValidate
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              onChange={(e) => setFirstName(e.target.value)}
              label="Nombre"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { color: "#E2E8F0" },
              }}
              InputLabelProps={{ style: { color: "#CBD5E1" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#64748B" },
                  "&:hover fieldset": { borderColor: "#F8FAFC" },
                  "&.Mui-focused fieldset": { borderColor: "#38BDF8" },
                },
              }}
            />
            <TextField
              onChange={(e) => setLastName(e.target.value)}
              label="Apellido"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { color: "#E2E8F0" },
              }}
              InputLabelProps={{ style: { color: "#CBD5E1" } }}
            />
            <TextField
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              label="Correo Electrónico"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { color: "#E2E8F0" },
              }}
              InputLabelProps={{ style: { color: "#CBD5E1" } }}
            />
            <TextField
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Contraseña"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { color: "#E2E8F0" },
              }}
              InputLabelProps={{ style: { color: "#CBD5E1" } }}
            />
            <Button
              onClick={handleSignup}
              color="primary"
              variant="contained"
              size="large"
              sx={{
                mt: 2,
                backgroundColor: "#38BDF8",
                color: "#1E293B",
                borderRadius: "25px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#0EA5E9",
                },
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Registrarse"
              )}
            </Button>
          </Box>

          <Typography variant="body2" sx={{ mt: 3, color: "#CBD5E1" }}>
            ¿Ya tienes una cuenta?{" "}
            <Link
              to="/login"
              style={{
                color: "#38BDF8",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              Iniciar sesión
            </Link>
          </Typography>
        </Card>
      </Container>
    </Box>
  );
}

export default Signup;
