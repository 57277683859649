import React from "react";
import { Box, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ShareButtons = () => {
  const urlToShare = "https://www.parcelmint.com";
  const textToShare =
    "Descubre ParcelMint, tu oportunidad para invertir en propiedades en El Salvador.";

  return (
    <Box display="flex" gap={2}>
      {/* Botón de Facebook */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<FacebookIcon />}
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          urlToShare
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Compartir en Facebook
      </Button>

      {/* Botón de Twitter */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<TwitterIcon />}
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          urlToShare
        )}&text=${encodeURIComponent(textToShare)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Compartir en X
      </Button>

      {/* Botón de WhatsApp */}
      <Button
        variant="contained"
        color="success"
        startIcon={<WhatsAppIcon />}
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${textToShare}\n${urlToShare}`
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          backgroundColor: "#25D366",
          "&:hover": {
            backgroundColor: "#1EBE59",
          },
        }}
      >
        Compartir en WhatsApp
      </Button>
    </Box>
  );
};

export default ShareButtons;
