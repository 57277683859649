import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

const PaymentSuccess = () => {
  const location = useLocation();
  const hasCaptured = useRef(false); // Track if capturePayment has been called
  const navigate = useNavigate();
  const [message, setMessage] = useState("Finalizing purchase...");
  const [loading, setLoading] = useState(true);

  // Capture payment function
  const capturePayment = useCallback(
    async (orderId) => {
      try {
        const response = await axios.post(
          process.env.NODE_ENV === "production"
            ? "https://www.parcelmint.com/api/property/capturePayment"
            : "http://localhost:5000/api/property/capturePayment",
          { orderId }
        );

        console.log("Capture response:", response.data);

        if (
          response.data.message ===
          "Purchase completed successfully and digital title created."
        ) {
          setMessage("purchase successful!");
          setLoading(false);
          setTimeout(() => navigate("/propiedades"), 2000); // Redirect after 2 seconds
        } else if (
          response.data.message ===
          "Order has already been captured and processed."
        ) {
          alert(
            "Order has already been processed. Thank you for your purchase!"
          );
          navigate("/propiedades");
        } else {
          setMessage("Unexpected response. Please contact support.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error capturing payment:", error);

        if (
          error.response &&
          error.response.data.details?.some(
            (detail) => detail.issue === "ORDER_ALREADY_CAPTURED"
          )
        ) {
          alert("This order has already been captured. Thank you!");
          navigate("/properties");
        } else {
          setMessage("Failed to finalize the purchase. Please try again.");
        }

        setLoading(false);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orderId = params.get("token"); // PayPal token for the orderId
    console.log("Order ID:", orderId);

    if (orderId && !hasCaptured.current) {
      hasCaptured.current = true; // Prevent re-capture
      capturePayment(orderId);
    } else if (!orderId) {
      console.error("Order ID not found in URL");
      setMessage("Order ID not found. Please try again.");
      setLoading(false);
    }
  }, [location, capturePayment]);

  return <Loading message={message} loading={loading} />;
};

export default PaymentSuccess;
