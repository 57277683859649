import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import CalculateIcon from "@mui/icons-material/Calculate";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#1E293B",
  color: "#E2E8F0",
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#0F172A",
  },
}));

const StyledIcon = styled(Box)(({ theme }) => ({
  width: 64,
  height: 64,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: "#38BDF8",
  color: "#0F172A",
  marginBottom: theme.spacing(2),
}));

// Menu links
const menuLinks = [
  { title: "Perfil", icon: <AccountCircle />, route: "/profile" },
  { title: "Contacto", icon: <ContactMailIcon />, route: "/Contacto" },
  { title: "Parcelmint", icon: <InfoIcon />, route: "/acerca" },
];

const MenuPage = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #0F172A, #1E293B)",
        py: 8,
        color: "#E2E8F0",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          textAlign="center"
          fontWeight="bold"
          sx={{ mb: 6, color: "#E2E8F0" }}
        >
          Navega en ParcelMint
        </Typography>

        <Grid container spacing={4}>
          {menuLinks.map((link, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                <CardActionArea component={Link} to={link.route}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      py: 4,
                    }}
                  >
                    <StyledIcon>{link.icon}</StyledIcon>
                    <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                      {link.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ color: "#94A3B8" }}
                    >
                      Descubre {link.title.toLowerCase()} en ParcelMint
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MenuPage;
