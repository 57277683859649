import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
} from "@mui/material";
import { Wheel } from "react-custom-roulette";
import TerminosDelSorteo from "./TerminosDelSorteo";

const SorteoPropiedad = ({ parcels }) => {
  const [participantes, setParticipantes] = useState([]);
  const [ganador, setGanador] = useState(null);
  const [spinResult, setSpinResult] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);

  // Cargar los nombres de los propietarios desde parcels
  useEffect(() => {
    const owners = parcels
      .slice(0, 9) // Máximo 9 participantes
      .map((parcel) => {
        if (parcel.owner && parcel.owner.firstName && parcel.owner.lastName) {
          return `${parcel.owner.firstName} ${parcel.owner.lastName}`;
        }
        return null; // Ignorar entradas inválidas
      })
      .filter((name) => name !== null); // Eliminar valores nulos
    setParticipantes(owners);
  }, [parcels]);

  // Preparar los datos para la rueda
  const wheelData = participantes.map((participante) => ({
    option: participante,
  }));

  // Realizar el sorteo
  const realizarSorteo = () => {
    if (participantes.length > 0) {
      setIsSpinning(true);
      const randomIndex = Math.floor(Math.random() * participantes.length);
      setSpinResult(randomIndex);
      setTimeout(() => {
        setGanador(participantes[randomIndex]);
        setIsSpinning(false);
      }, 4000); // Duración del giro
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "600px",
        margin: "0 auto",
        padding: 4,
        textAlign: "center",
        color: "black",
      }}
    >
      <Typography color="black" variant="h4" sx={{ marginBottom: 2 }}>
        Sorteo de Propiedad de 1 Acre
      </Typography>

      <Typography color="black" variant="body1" sx={{ marginBottom: 4 }}>
        Los siguientes participantes están incluidos en el sorteo de una
        propiedad de 1 acre.
      </Typography>

      <List
        sx={{
          maxHeight: 200,
          overflowY: "auto",
          marginBottom: 3,
          color: "black",
        }}
      >
        {participantes.map((participante, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText color="black" primary={participante} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {participantes.length > 0 && (
        <Box sx={{ marginBottom: 3 }}>
          <Wheel
            mustStartSpinning={isSpinning}
            prizeNumber={spinResult}
            data={wheelData}
            outerBorderColor={"#ccc"}
            outerBorderWidth={10}
            innerRadius={50}
            radiusLineColor={"#ccc"}
            radiusLineWidth={6}
            textColors={["#333"]}
            fontSize={14}
            backgroundColors={["#FFEB3B", "#FF5722", "#4CAF50", "#03A9F4"]}
            onStopSpinning={() => setIsSpinning(false)}
          />
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={realizarSorteo}
        disabled={participantes.length === 0 || isSpinning}
        sx={{ marginBottom: 3 }}
      >
        Realizar Sorteo
      </Button>

      {ganador && (
        <Alert severity="success" variant="filled">
          <Typography variant="h6">¡El ganador es: {ganador}!</Typography>
        </Alert>
      )}
    </Box>
  );
};

export default SorteoPropiedad;
