import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import property from "../../api/property";

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await property.getAll();
        console.log("properties ", response.data);
        setProperties(response.data);
      } catch (error) {
        setError("Failed to load properties");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography color="black" variant="h1" align="center" gutterBottom>
        {properties.length === 1 ? "Terreno Propuesto" : "Terrenos Propuestos"}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={4}
        sx={{ mt: 4 }}
      >
        {properties.map((property) => (
          <Card
            component={Link}
            to={`/property-details/${property._id}`}
            key={property._id}
            sx={{
              position: "relative",
              width: "100%", // Full width of the container
              maxWidth: "800px", // Optional max width for larger screens
              height: 400,
              borderRadius: 3,
              textDecoration: "none",
              boxShadow: 3,
              backgroundImage: `url(${property.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
              overflow: "hidden",

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Space between content and button
            }}
          >
            {/* Overlay for readability */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                zIndex: 1, // Place overlay behind content
              }}
            />
            <CardContent
              sx={{
                position: "relative",
                zIndex: 2, // Ensures content is above overlay
                color: "white",
                fontFamily: "Roboto, sans-serif", // Modern font style
              }}
            >
              <Typography color="White" variant="poster" gutterBottom>
                {property.title}
              </Typography>
              <Typography variant="body2" color="inherit">
                {property.location}
              </Typography>
              <Box mt={2}>
                <Typography variant="h6" color="white">
                  ${property.financingOptions.downPayment}{" "}
                  {property && property.priceUnit === "acre"
                    ? "Por Lote"
                    : "Por Parcela"}
                </Typography>
              </Box>
            </CardContent>
            <CardActions
              style={{ textAlign: "center" }}
              sx={{
                position: "relative",
                zIndex: 2, // Ensures button is above overlay
                width: "100%",
              }}
            ></CardActions>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default Properties;
