import React, { useState, useContext, useEffect } from "react";
import { auth } from "./../firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "./../firebase/AuthContext";
import userApi from "./../api/user";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Container,
} from "@mui/material";

function Login() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
  }, [user, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      userApi
        .loginByEmail(email, password)
        .then(() => {
          signInWithEmailAndPassword(auth, email, password)
            .then(() => navigate("/profile"))
            .catch((er) =>
              setError("Error de inicio de sesión: Credenciales inválidas")
            );
        })
        .catch(() =>
          setError("Error de inicio de sesión: Usuario no encontrado")
        );
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #0F172A, #1E293B)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            backgroundColor: "#1E293B",
            borderRadius: "12px",
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
            color: "#E2E8F0",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="center"
              color="#F8FAFC"
              gutterBottom
              sx={{ mb: 2 }}
            >
              Bienvenido de Nuevo
            </Typography>

            <Typography
              variant="body1"
              textAlign="center"
              color="#CBD5E1"
              sx={{ mb: 3 }}
            >
              Inicia sesión en tu cuenta de ParcelMint
            </Typography>

            <Box component="form" noValidate autoComplete="off">
              <TextField
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                label="Correo Electrónico"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  style: { color: "#E2E8F0", borderColor: "#64748B" },
                }}
                InputLabelProps={{ style: { color: "#CBD5E1" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#64748B" },
                    "&:hover fieldset": { borderColor: "#F8FAFC" },
                    "&.Mui-focused fieldset": { borderColor: "#38BDF8" },
                  },
                }}
              />
              <TextField
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                label="Contraseña"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  style: { color: "#E2E8F0" },
                }}
                InputLabelProps={{ style: { color: "#CBD5E1" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#64748B" },
                    "&:hover fieldset": { borderColor: "#F8FAFC" },
                    "&.Mui-focused fieldset": { borderColor: "#38BDF8" },
                  },
                }}
              />
              {error && (
                <Typography
                  color="error"
                  variant="body2"
                  textAlign="center"
                  sx={{ mt: 1 }}
                >
                  {error}
                </Typography>
              )}
              <Button
                onClick={handleLogin}
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  backgroundColor: "#38BDF8",
                  color: "#1E293B",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#0EA5E9",
                  },
                }}
              >
                Iniciar Sesión
              </Button>
            </Box>

            <Typography
              variant="body2"
              textAlign="center"
              sx={{ mt: 3, color: "#CBD5E1" }}
            >
              ¿No tienes una cuenta?{" "}
              <Link
                to="/signup"
                style={{ color: "#38BDF8", textDecoration: "none" }}
              >
                Regístrate
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default Login;
