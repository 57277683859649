import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = ({ message }) => (
  <Box
    sx={{
      position: "fixed", // Full screen overlay
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.7)", // Slight opacity
      zIndex: 9999, // Ensures it stays on top
    }}
  >
    <CircularProgress />
    <Typography variant="body1" mt={2} color="black">
      {message}
    </Typography>
  </Box>
);

export default Loading;
